@import 'theme';

$badge-height: 30px;
$badge-height-desktop: 40px;
$badge-height-large: 50px;
$badge-height-desktop-large: 55px;

.get-it-on-google-play, .download-on-the-app-store {
  margin: 10px;
  display: inline-block;

  a {
    line-height: 0;
    vertical-align: baseline
  }

  img {
    height: 100%;
  }

  height: $badge-height;
  @media screen and (min-width: $min-breakpoint) {
    height: $badge-height-desktop;
  }

  &.large {
    height: $badge-height-large;
    @media screen and (min-width: $min-breakpoint) {
      height: $badge-height-desktop-large;
    }
  }
}
