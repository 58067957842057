@import "theme";

.reviews {

  .ln-c-icon {
    path {
      fill: $primary-color;
    }
  }

  .stars {
    margin-bottom: 10px;
  }

  .review-block {
    word-break: break-word;

    & > strong {
      font-size: 1.4em;
    }
  }

  .slick-slider {
    overflow-x: hidden;
  }

  .slick-dots {
    position: static;
    margin-top: 10px;

    li button:before {
      font-size: 10px;
    }
  }
}
