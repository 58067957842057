@import 'theme';

.how-to-container {
    position: relative;
}

.ghost-hand {
    width: 40%;
    max-width: 388.88px;
    position: absolute;
    bottom: 0;
    right: 0;

    @media screen and (max-width: $max-breakpoint) {
        display: none;
    }
}

.step-container {
    margin-bottom: -10px;

    @media screen and (min-width: $min-breakpoint) {
        display: inline-block;
    }

    h4 {
        @media screen and (max-width: $max-breakpoint) {
            text-align: center;
        }
    }

    .steps {
        margin-bottom: 35px;

        @media screen and (max-width: $max-breakpoint) {
            margin: 35px auto;
            width: 90%;
        }

        .step-number {
            display: inline-block;
            width: 32px;
            font-size: 72px;
            line-height: 0.72;

            @media screen and (min-width: $min-breakpoint) {
                font-size: 100px;
                width: 52px;
            }
        }

        .step-body {
            font-weight: normal;
            padding-left: 20px;
            min-width: 200px;
            width: 85%;
            display: inline-block;

            @media screen and (min-width: $min-breakpoint) {
                max-width: 400px;
                width: 80%;
            }
        }

        span {
            vertical-align: text-top;

            @media screen and (min-width: $min-breakpoint) {
                vertical-align: middle;
            }
        }
    }
}
