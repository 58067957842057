@import 'theme';

.mission-statement {
  h3 {
    margin: 0 auto;

    @media screen and (max-width: $max-breakpoint) {
      font-size: 22px;
    }

    br {
      line-height: 2;
    }
  }

  &.ln-o-container {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
