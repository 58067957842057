.faqs {
  .faq {
    white-space: pre-wrap;
    margin: 10px auto;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
