$js-orange: #f39000;
$js-orange-dark: #f26c00;
$mid-grey: #f5f5f5;

$primary-color: $js-orange-dark;
$bg-grey: $mid-grey;


$min-breakpoint: 721px;
$max-breakpoint: 720px;
