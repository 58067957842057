@import 'theme';

.header-container {

  .logo {
    height: 60px;
    margin: 0 auto;
    padding: 10px;

    @media screen and (max-width: $max-breakpoint) {
      display: block;
    }
    @media screen and (min-width: $min-breakpoint) {
      height: 80px;
    }
  }

  .applinks-container {
    @media screen and (min-width: $min-breakpoint) {
      float: right;
    }
  }

}
