@import 'theme';

.hero {
    background-color: $primary-color;

    & > img {
        margin: 0 auto;
        display: block;
    }
}
